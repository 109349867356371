import React from 'react';
import Cookies from 'universal-cookie';
import { Box, SxProps, Theme } from '@mui/material';
import { Footer } from '@care/react-component-lib';
import { defaultGlobalFooterContent } from '@care/navigation';
import logger from '@/lib/clientLogger';
import {
  FOOTER_ABOUT_CARE_LINKS,
  FOOTER_GET_HELP_LINKS,
  FOOTER_DISCOVER_LINKS,
  FOOTER_SUBVERTICAL_LINKS,
  FOOTER_PROGRAM_AND_RESOURCES_LINKS,
  CZEN_VISITOR_COOKIE_KEY,
  CZEN_SESSION_COOKIE_KEY,
  LOCAL_CAREGIVERS_LINKS,
  INTERNATIONAL_LINKS,
} from '@/constants';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';

type MenuLinkObj = {
  label: string;
  address: string;
};

type GridElement = {
  label: string;
  items: MenuLinkObj[];
};

export const getAllLinks = ({ isDesktop }: { isDesktop: boolean }) => ({
  aboutCare: {
    label: 'About Care.com',
    items: FOOTER_ABOUT_CARE_LINKS,
    className: isDesktop ? '' : 'hideLabel',
  },
  getHelp: {
    label: 'Get help',
    items: FOOTER_GET_HELP_LINKS,
    className: isDesktop ? '' : 'hideLabel',
  },
  discover: {
    label: 'Discover',
    items: FOOTER_DISCOVER_LINKS,
    className: isDesktop ? '' : 'hideLabel',
  },
  forChildren: {
    label: 'For children',
    items: FOOTER_SUBVERTICAL_LINKS.forChildren,
    className: isDesktop ? 'forChildren' : 'hideLabel',
  },
  forAdultsSeniors: {
    label: 'For adults & seniors',
    items: FOOTER_SUBVERTICAL_LINKS.forAdultsSeniors,
    className: isDesktop ? 'two-items' : 'hideLabel',
  },
  forPets: {
    label: 'For pets',
    items: FOOTER_SUBVERTICAL_LINKS.forPets,
    className: isDesktop ? 'two-items' : 'hideLabel',
  },
  forHome: {
    label: 'For home & lifestyle',
    items: FOOTER_SUBVERTICAL_LINKS.forHome,
    className: isDesktop ? 'two-items' : 'hideLabel',
  },
  programsAndResources: {
    label: 'Programs & resources',
    items: FOOTER_PROGRAM_AND_RESOURCES_LINKS,
    className: isDesktop ? 'programsAndResources' : 'hideLabel',
  },
  localCaregiversByState: {
    label: 'By State',
    items: LOCAL_CAREGIVERS_LINKS().byState,
    className: isDesktop ? '' : 'hideLabel',
  },
  localCaregiversBabysitters: {
    label: 'Babysitters',
    items: LOCAL_CAREGIVERS_LINKS().babysitters,
    className: isDesktop ? '' : 'hideLabel',
  },
  localCaregiversNannies: {
    label: 'Nannies',
    items: LOCAL_CAREGIVERS_LINKS().nannies,
    className: isDesktop ? '' : 'hideLabel',
  },
  localCaregiversDaycares: {
    label: 'Daycares',
    items: LOCAL_CAREGIVERS_LINKS().daycare,
    className: isDesktop ? '' : 'hideLabel',
  },
  localCaregiversSeniorCare: {
    label: 'Senior Care',
    items: LOCAL_CAREGIVERS_LINKS().seniorCare,
    className: isDesktop ? '' : 'hideLabel',
  },
  localCaregiversPetSitters: {
    label: 'Pet Sitters',
    items: LOCAL_CAREGIVERS_LINKS().petSitters,
    className: isDesktop ? '' : 'hideLabel',
  },
  localCaregiversHousekeepers: {
    label: 'Housekeepers',
    items: LOCAL_CAREGIVERS_LINKS().housekeepers,
    className: isDesktop ? '' : 'hideLabel',
  },
  localCaregiversTutors: {
    label: 'Tutors',
    items: LOCAL_CAREGIVERS_LINKS().tutors,
    className: isDesktop ? '' : 'hideLabel',
  },
  internationalLinks: {
    label: 'Find care around the world',
    items: INTERNATIONAL_LINKS,
    className: 'hideLabel',
  },
  disclaimerLinks: {
    label: 'disclaimer',
    items: defaultGlobalFooterContent.legalLinks.map(({ isAbsolute, address, ...link }) => ({
      ...link,
      address: isAbsolute ? address : `/${address}`,
      rel: 'nofollow',
    })),
    className: 'disclaimerLinkList',
  },
});

export const localCaregiversSections = [
  {
    label: 'By State',
    items: LOCAL_CAREGIVERS_LINKS().byState,
  },
  {
    label: 'Babysitters',
    items: LOCAL_CAREGIVERS_LINKS().babysitters,
  },
  {
    label: 'Nannies',
    items: LOCAL_CAREGIVERS_LINKS().nannies,
  },
  {
    label: 'Daycares',
    items: LOCAL_CAREGIVERS_LINKS().daycare,
  },
  {
    label: 'Senior Care',
    items: LOCAL_CAREGIVERS_LINKS().seniorCare,
  },
  {
    label: 'Pet Sitters',
    items: LOCAL_CAREGIVERS_LINKS().petSitters,
  },
  {
    label: 'Housekeepers',
    items: LOCAL_CAREGIVERS_LINKS().housekeepers,
  },
  {
    label: 'Tutors',
    items: LOCAL_CAREGIVERS_LINKS().tutors,
  },
];

const cookies = new Cookies();
const czenVisitorId = cookies.get(CZEN_VISITOR_COOKIE_KEY);
const czenSessionId = cookies.get(CZEN_SESSION_COOKIE_KEY);

export const gridElement = (
  element: GridElement,
  containerClass?: SxProps<Theme>,
  blankLabel: boolean = false,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
) => {
  return blankLabel ? (
    <Box
      onClick={(e) => {
        onClick?.(e);
      }}
      sx={containerClass}>
      <Footer links={element.items} type="group" groupTitle="" />
    </Box>
  ) : (
    <Box
      onClick={(e) => {
        onClick?.(e);
      }}
      sx={containerClass}>
      {element.label === 'disclaimer' ? (
        <Footer links={element.items} type="basic" />
      ) : (
        <Footer links={element.items} type="group" groupTitle={element.label} />
      )}
    </Box>
  );
};

export const handleSocialIconsClick = (e: any, ctaName: string, href: string) => {
  e.preventDefault();
  AnalyticsHelper.logEvent({
    name: 'CTA_Interacted',
    data: {
      cta_text: ctaName,
      enrollment_step: 'member type',
      source: 'footer',
    },
  });

  logger.info({
    name: 'CTA_Interacted',
    data: {
      cta_text: ctaName,
      enrollment_step: 'member type',
      source: 'footer',
      czenVisitorId,
      czenSessionId,
    },
  });

  if (ctaName === 'Care@Work' || ctaName === 'List your business') {
    window.location.assign(href);
  } else {
    window.open(href, '_blank', 'noopener');
  }
};

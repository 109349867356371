import { DisclaimerProps } from './Disclaimer';
import HomePageFooterDW from './HomePageFooterDW';
import HomePageFooterMW from './HomePageFooterMW';

type HomePageFooterProps = {
  renderDesktopView?: boolean;
} & DisclaimerProps;

function HomePageFooter({
  renderDesktopView = false,
  lifeMartDisclaimer = false,
}: HomePageFooterProps) {
  return renderDesktopView ? (
    <HomePageFooterDW lifeMartDisclaimer={lifeMartDisclaimer} />
  ) : (
    <HomePageFooterMW lifeMartDisclaimer={lifeMartDisclaimer} />
  );
}

export default HomePageFooter;

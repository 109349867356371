export type DisclaimerProps = { lifeMartDisclaimer?: boolean };
const Disclaimer = ({ lifeMartDisclaimer = false }: DisclaimerProps) => {
  return (
    <>
      {lifeMartDisclaimer && (
        <>
          *Discounts are available through Care&apos;s discount platform known as LifeMart. Care
          does not guarantee the availability of discounts or merchants. Discounts are subject to
          change or withdrawal without notice, and any applicable time restrictions. Discount
          merchants are independent third parties and purchases of goods or services are at your own
          risk. All fees and costs associated with the goods and services purchased are the
          responsibility of the purchaser. Discounts are subject to the Terms and Conditions and
          Privacy Policy which are accessible through the LifeMart site.
          <br />
          <br />
        </>
      )}
      Care.com does not employ any caregiver and is not responsible for the conduct of any user of
      our site. All information in member profiles, job posts, applications, and messages is created
      by users of our site and not generated or verified by Care.com. You need to do your own
      diligence to ensure the job or caregiver you choose is appropriate for your needs and complies
      with applicable laws.
      <br />
      <br />
      Care.com® HomePay<sup>SM</sup> is a service provided by Breedlove and Associates, LLC, a
      Care.com company.
      <br />
      <br />
      Care.com and &quot;There for you&quot; are service marks or registered service marks of
      Care.com, Inc. © 2007-{new Date().getFullYear()} Care.com, Inc. All rights reserved.
    </>
  );
};

export default Disclaimer;
